import { last } from "lodash"
import { productPagePath } from "../../../root/routing/urls"
import {
  Product,
  ProductCharacteristics,
  ProductExtraData,
  ProductInfo,
  ProductMedia,
  ProductMetadata,
  ProductVariant,
  ProductVariantCharacteristics,
  ProductVariantContents,
  ProductVariantInfo,
  ProductVariantInventory,
  ProductVariantMedia,
  ProductVariantMetadata,
} from "../../../types/product"
import { PartialGridItemProduct } from "../../../ui/organisms/Product/ProductGridItem/types"
import { AlgoliaProductVariant } from "../types/doc"

const toProductInfo = (doc: AlgoliaProductVariant): ProductInfo => ({
  name: doc.contents.name,
  productId: doc.productId,
  slug: doc.contents.slug,
  url: productPagePath(doc.contents.slug),
})

const toProductMedia = (doc: AlgoliaProductVariant): ProductMedia => ({
  productImages:
    doc.contents.productImages?.map((x) => ({
      asset: x.asset,
    })) ?? [],
  campaignImages: [],
})

const toProductMetadata = (doc: AlgoliaProductVariant): ProductMetadata => ({
  brand: {
    code: doc.brandCode,
    name: doc.contents.brandName,
  },
  categoryPath: doc.mainCategory.map((x) => ({
    code: x.code,
    level: x.level,
    name: x.name,
    slug: "",
  })),
  tags: [],
  // tags: doc.tags.map((x) => ({
  //   categoryName: x.categoryName,
  //   code: x.key, // .value.split(":")[1],
  //   key: x.key,
  //   value: x.value,
  //   name: x.name,
  // })),
})

const isBestSeller = (doc: AlgoliaProductVariant) => doc.inventory.bestSeller
const isExclusive = (doc: AlgoliaProductVariant) =>
  doc._tags?.includes("exclusive|true")

const isNew = (doc: AlgoliaProductVariant) => doc._tags?.includes("new|true")

const toProductCharacteristics = (
  doc: AlgoliaProductVariant
): ProductCharacteristics => ({
  bestSeller: isBestSeller(doc),
  exclusive: isExclusive(doc),
  new: isNew(doc),
})

const toProductExtraData = (doc: AlgoliaProductVariant): ProductExtraData => ({
  noPromo: false,
  promotions: [],
})

const toProductVariantContents = (
  doc: AlgoliaProductVariant
): ProductVariantContents => ({
  name: doc.contents.name,
  composition: doc.contents.composition,
  fragrance: doc.contents.fragrance,
  fragranceFamily: doc.contents.fragranceFamily,
  highlights: doc.contents.highlights,
  ingredients: doc.contents.ingredients,
  instructions: doc.contents.instructions,
  longDescription: doc.contents.longDescription,
  plus: doc.contents.plus,
  shortDescription: doc.contents.shortDescription,
  technology: doc.contents.technology,
  testResults: doc.contents.testResults,
  contacts: doc.contents.contacts,
  safetyWarnings: doc.contents.safetyWarnings,
})

const toProductVariantInventory = (
  doc: AlgoliaProductVariant
): ProductVariantInventory => ({
  available: doc.inventory.active,
  currency: "eur",
  maxSellQuantity: doc.inventory.maxSellQuantity,
  price: doc.inventory.price,
  bestSeller: doc.inventory.bestSeller,
  compareAtPrice: doc.inventory.compareAtPrice,
  discountPercent: doc.inventory.discountPercent ?? 0,
})

const toProductVariantInfo = (
  doc: AlgoliaProductVariant
): ProductVariantInfo => ({
  default: true,
  variantId: doc.eanCode,
  // color: undefined
  // quantity
  slug: doc.contents.slug,
  validity: {
    validFrom: doc.validFrom,
    validTo: doc.validTo,
  },
})

// const toProductShopifyData = (
//   doc: AlgoliaProductVariant
// ): ProductShopifyData => ({})

const toProductVariantMetadata = (
  doc: AlgoliaProductVariant
): ProductVariantMetadata => ({
  brand: {
    code: doc.brandCode,
    name: doc.contents.brandName,
  },
})

const toProductVariantMedia = (
  doc: AlgoliaProductVariant
): ProductVariantMedia => ({
  productImages:
    doc.contents.productImages?.map((x) => ({
      asset: x.asset,
    })) ?? [],
  campaignImages: [],
})

const toProductVariantCharacteristics = (
  doc: AlgoliaProductVariant
): ProductVariantCharacteristics => ({
  bestSeller: isBestSeller(doc),
  exclusive: isExclusive(doc),
  new: isNew(doc),
})

const toProductVariant = (doc: AlgoliaProductVariant): ProductVariant => ({
  info: toProductVariantInfo(doc),
  media: toProductVariantMedia(doc),
  metadata: toProductVariantMetadata(doc),
  characteristics: toProductVariantCharacteristics(doc),
  contents: toProductVariantContents(doc),
  inventory: toProductVariantInventory(doc),
  // shopify: toProductShopifyData(doc),
})

export const toProduct = (doc: AlgoliaProductVariant): Product => {
  const variant = toProductVariant(doc)
  return {
    info: toProductInfo(doc),
    media: toProductMedia(doc),
    metadata: toProductMetadata(doc),
    characteristics: toProductCharacteristics(doc),
    extraData: toProductExtraData(doc),
    defaultVariant: variant,
    variants: [variant],
  }
}

export const toPartialGridItemProduct = (
  doc: AlgoliaProductVariant
): PartialGridItemProduct => {
  return {
    defaultVariant: {
      info: {
        brandName: doc.contents.brandName,
        slug: doc.contents.slug,
        name: doc.contents.name,
        new: isNew(doc),
        productId: doc.productId,
        variantId: doc.eanCode,
        categoryName: last(doc.mainCategory ?? [])?.name,
        // promoLogoUrl:
      },
      media: {
        imageAsset: doc.contents.productImages?.[0].asset,
      },
      inventory: {
        available: doc.inventory.active,
        price: doc.inventory.price,
        compareAtPrice: doc.inventory.compareAtPrice,
        discountPercent: doc.inventory.discountPercent ?? 0,
      },
    },
  }
}
